import React, { useMemo, useState } from 'react'
import { TableV2 } from 'src/components'

import { graphs as colorArray } from 'src/utils/graphs'

interface IChartTableItem {
  id: number
  name: string
  amount: number
}

interface Props {
  title: string
  currency?: boolean
  updateGraph?: (index: number[]) => void
  items: IChartTableItem[]
}

/**
 * Table component for charts
 * @component
 * @param {string} title Title of the table
 * @param {boolean} currency If the values are currency
 * @param {function} updateGraph Function to update the graph, if the user clicks on a row
 * @param {IChartTableItem[]} items List of items to display
 */
const ChartTableComponent = ({ title, currency, updateGraph, items }: Props) => {
  const [hiddenData, setHiddenData] = useState<number[]>([])

  /**
   * Handle the click on a row, save the index of that data
   * @param index Index of the row clicked
   */
  const handleRowClick = index => {
    if (hiddenData.includes(index)) {
      setHiddenData(hiddenData.filter(i => i !== index))
    } else {
      setHiddenData([...hiddenData, index])
    }
    updateGraph?.bind(null, hiddenData)
  }

  const headers: ITableHeader[] = [
    {
      show: true,
      label: '',
      value: [],
      type: 'text',
      custom: (ignore, index) => (
        <div className={'index-container'}>
          {index}
          <div
            className={'g-color'}
            style={{
              backgroundColor: hiddenData.includes(index)
                ? 'white'
                : colorArray[index] + '66',
              borderColor: colorArray[index],
              borderWidth: 2,
            }}
          />
        </div>
      ),
    },
    {
      show: true,
      label: 'Nombre',
      value: ['name'],
      type: 'text',
    },
    {
      show: true,
      label: title,
      value: ['amount'],
      type: currency ? 'currency' : 'text',
    },
  ]

  const itemsSorted = useMemo(() => {
    return items.sort((a, b) => b.amount - a.amount)
  }, [items])

  return (
    <TableV2
      /* @ts-expect-error  */
      headers={headers}
      items={itemsSorted}
    />
  )
}

export const ChartTable = React.memo(ChartTableComponent)
