import React, { useContext, useEffect, useState } from 'react'

import Content from './content/Content'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { ToolBar } from './components/toolbar/ToolBar'
import SideBar from './components/sidebar/SideBar'
import Alert from './components/Alert/Alert'
import PurchaseDetail from './content/Purchase/Detail/PurchaseDetail/PurchaseDetail'

import { getLastVersion } from './actions/versions.actions'
import { FirebaseContext } from './components/FirebaseProvider/FirebaseProvider'
import Printers from './components/Printer/Printers'

export const history = createBrowserHistory()

function App() {
  const [done, setDone] = useState(false)
  const firebaseSession = useContext(FirebaseContext)

  const user = firebaseSession?.user
  const isSignedIn = Boolean(user)

  useEffect(() => {
    setTimeout(() => {
      getLastVersion()
      setDone(true)
    }, 1000)
  }, [])

  if (user === false) {
    return <></>
  }

  return (
    <div style={{ display: done ? 'block' : 'none' }}>
      <Router history={history}>
        <div style={{ flex: 1 }}>
          <Alert />
          <Printers />
          <PurchaseDetail />

          <Content user={user} isSignedIn={isSignedIn} />
        </div>
      </Router>
    </div>
  )
}

export default App
